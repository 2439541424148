export default [
  // *===============================================---*
  // *--------- SETTINGS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/config/settings/list',
    name: 'config-settings-list',
    component: () => import('@/views/config/setting/SettingsList.vue'),
  },
  {
    path: '/config/settings/add',
    name: 'config-settings-add',
    component: () => import('@/views/config/setting/settings-add/SettingsAdd.vue'),
  },
  /* {
    path: '/config/settings/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/config/setting/SettingsView.vue'),
  }, */
  {
    path: '/config/settings/edit/:id',
    name: 'config-settings-edit',
    component: () => import('@/views/config/setting/settings-edit/SettingsEdit.vue'),
  },

  // *===============================================---*
  // *--------- DESTINATIONS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/config/destinos',
    name: 'config-destinations-list',
    component: () => import('@/views/config/destinations/Destinations.vue'),
  },

  // *===============================================---*
  // *--------- KITS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/config/kits',
    name: 'config-kits-list',
    component: () => import('@/views/config/kits/Kits.vue'),
  },
]