export default [
  // *===============================================---*
  // *--------- INVENTORIES -------------------------------------------*
  // *===============================================---*
  {
    path: '/inventario',
    name: 'inventory',
    component: () => import('@/views/inventories/Inventories.vue'),
  },
  {
    path: '/inventario/ingreso-seriales/:stowage_id',
    name: 'read-inventory-products',
    component: () => import('@/views/inventories/inventory-products/InventoryProducts.vue'),
  },
  {
    path: '/inventario/ingreso-por-caja/:stowage_id',
    name: 'read-inventory-by-box',
    component: () => import('@/views/inventories/inventory-products-by-box/InventoryByBox.vue'),
  },
  {
    path: '/inventario/ingreso-por-serial/:stowage_id',
    name: 'read-inventory-by-serial',
    component: () => import('@/views/inventories/inventory-products-by-serial/InventoryBySerial.vue'),
  },
]