export default [
  // *===============================================---*
  // *--------- ORDERS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/pedido/crear',
    name: 'create-order',
    component: () => import('@/views/orders/create-order/CreateOrder.vue'),
  },
  {
    path: '/pedidos',
    name: 'order-list',
    component: () => import('@/views/orders/OrdersList.vue'),
  },
  {
    path: '/alistamiento/:order_id',
    name: 'enlistment',
    component: () => import('@/views/orders/enlistment-order/EnlistmentOrder.vue'),
  },
  {
    path: '/pedido/detalle/:order_id',
    name: 'order-detail',
    component: () => import('@/views/orders/order-detail/OrderDetail.vue'),
  },
  {
    path: '/pedido/productos/:order_id',
    name: 'order-products',
    component: () => import('@/views/orders/order-products/OrderProducts.vue'),
  },
  {
    path: '/pedido/editar/:order_id',
    name: 'order-edit',
    component: () => import('@/views/orders/edit-order/OrderEdit.vue'),
  },
  {
    path: '/pedido/novedades/:order_id',
    name: 'order-newness',
    component: () => import('@/views/orders/order-newnesses/OrderNewness.vue'),
  },
  {
    path: '/pedido/agregar-novedad/:order_id',
    name: 'add-newness',
    component: () => import('@/views/orders/order-newnesses/add-newness/AddNewness.vue'),
  },
  {
    path: '/pedido/estibas/:order_id',
    name: 'order-stowages',
    component: () => import('@/views/orders/order-stowage/OrderStowage.vue'),
  },

  // *===============================================---*
  // *--------- POP ORDERS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/pedidos-pop',
    name: 'pop-order-list',
    component: () => import('@/views/orders/pop-orders/PopOrders.vue'),
    meta: {
      pageTitle: 'Pedidos POP'
    }
  },
  {
    path: '/pedido-pop/crear',
    name: 'create-pop-order',
    component: () => import('@/views/orders/pop-orders/create-pop-order/CreatePopOrder.vue'),
    meta: {
      pageTitle: 'Crear pedido POP'
    }
  },
  {
    path: '/pedido-pop/detalle/:pop_order_id',
    name: 'pop-order-detail',
    component: () => import('@/views/orders/pop-orders/pop-order-detail/PopOrderDetail.vue'),
  },

  // *===============================================---*
  // *--------- POP PRODUCTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/productos-pop',
    name: 'pop-product-list',
    component: () => import('@/views/orders/pop-orders/pop-products/PopProducts.vue'),
    meta: {
      pageTitle: 'Productos POP'
    }
  },

  // *===============================================---*
  // *--------- SIM ORDERS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/pedidos-sim',
    name: 'sim-order-list',
    component: () => import('@/views/orders/sim-orders/SimOrders.vue'),
    meta: {
      pageTitle: 'Pedidos de SIM'
    }
  },
  {
    path: '/pedido-sim/crear',
    name: 'create-sim-order',
    component: () => import('@/views/orders/sim-orders/create-sim-order/CreateSimOrder.vue'),
    meta: {
      pageTitle: 'Crear pedido SIM'
    }
  },
  {
    path: '/alistamiento-sims/:order_id',
    name: 'enlistment-sim-order',
    component: () => import('@/views/orders/sim-orders/enlistment-sim-order/ReadSim.vue'),
    meta: {
      pageTitle: 'Alistamiento pedido SIMs'
    }
  },
  {
    path: '/pedido-sim/detalle/:sim_order_id',
    name: 'sim-order-detail',
    component: () => import('@/views/orders/sim-orders/sim-order-detail/SimOrderDetail.vue'),
  },
  {
    path: '/pedido-sim/sims/:order_id',
    name: 'order-sims',
    component: () => import('@/views/orders/sim-orders/order-sims/OrderSims.vue'),
  },

]