export default [
  {
    path: '/aliados',
    name: 'allies',
    component: () => import('@/views/allies/Allies.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/aliados/onboarding',
    name: 'allies-onboarding',
    component: () => import('@/views/allies/OnboardingAllie.vue'),
    meta: {
      layout: 'full',
    },
  },
]
