export default [
  // *===============================================---*
  // *--------- REQUESTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/requests/list',
    name: 'requests-list',
    component: () => import('@/views/requests/RequestsList.vue'),
  },
  {
    path: '/requests/view/:id',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
]