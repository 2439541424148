export default [
  // *===============================================---*
  // *--------- PRODUCTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/buscar-seriales',
    name: 'search-products',
    component: () => import('@/views/products/search-products/SearchProducts.vue'),
  },
  {
    path: '/subir-seriales',
    name: 'upload-products',
    component: () => import('@/views/products/upload-products/UploadProducts.vue'),meta: {
      pageTitle: 'Subir seriales',
    }
  },
  {
    path: '/novedades',
    name: 'product-newness',
    component: () => import('@/views/newness/Newness.vue'),
  },
  {
    path: '/numeros-de-transporte',
    name: 'order-numbers',
    component: () => import('@/views/products/order-numbers/OrderNumbers.vue'),
  },

  // *===============================================---*
  // *--------- ENLISTMENT -------------------------------------------*
  // *===============================================---*
  {
    path: '/alistamiento-temprano',
    name: 'early-enlistment',
    component: () => import('@/views/enlistment/EarlyEnlistment.vue'),
  },
  {
    path: '/alistamiento-temprano/lectura-seriales/:stowage_id',
    name: 'read-product-early-enlistment',
    component: () => import('@/views/enlistment/products-enlistment/ProductEnlistment.vue'),
  },
  {
    path: '/alistamiento-para-despacho',
    name: 'enlistment-dispatch',
    component: () => import('@/views/enlistment/enlistment-dispatch/EnlistmentDispatch.vue'),
  },
]