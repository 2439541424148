export default [
  // *===============================================---*
  // *--------- SIMS -------------------------------------------*
  // *===============================================---*
  {
    path: '/buscar-seriales',
    name: 'search-products',
    component: () => import('@/views/products/search-products/SearchProducts.vue'),
  },
  {
    path: '/subir-seriales-sim',
    name: 'upload-sims',
    component: () => import('@/views/sims/upload-sims/UploadSims.vue'),
    meta: {
      pageTitle: 'Subir seriales sims',
    }
  },
  {
    path: '/novedades-sims',
    name: 'product-newness',
    component: () => import('@/views/newness/Newness.vue'),
  },
]